<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người được Trung tâm đào tạo, nâng cao kỹ năng</label>
          <b-form-input
            type="number"
            :value="workerTraining"
            @input="updateWorkerTraining"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người tham gia các khóa đào tạo kỹ năng (kỹ năng xin việc, phỏng
            vấn...)</label>
          <b-form-input
            type="number"
            :value="workerTrainingSkill"
            @input="updateWorkerTrainingSkill"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người tham gia các khóa đào tạo nghề nghiệp</label>
          <b-form-input
            type="number"
            :value="workerTrainingJob"
            @input="updateWorkerTrainingJob"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người được Trung tâm đào tạo tìm được việc làm qua Trung
            tâm</label>
          <b-form-input
            type="number"
            :value="workerTrainingRecruit"
            @input="updateWorkerTrainingRecruit"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    workerTraining: {
      type: Number,
      required: true,
    },
    workerTrainingSkill: {
      type: Number,
      required: true,
    },
    workerTrainingJob: {
      type: Number,
      required: true,
    },
    workerTrainingRecruit: {
      type: Number,
      required: true,
    },
  },
  methods: {
    updateWorkerTraining(val) {
      this.$emit('update:workerTraining', Number(val))
    },
    updateWorkerTrainingSkill(val) {
      this.$emit('update:workerTrainingSkill', Number(val))
    },
    updateWorkerTrainingJob(val) {
      this.$emit('update:workerTrainingJob', Number(val))
    },
    updateWorkerTrainingRecruit(val) {
      this.$emit('update:workerTrainingRecruit', Number(val))
    },
  },
}
</script>
