<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>Số người lao động được tư vấn</label>
          <b-form-input
            type="number"
            :value="workerAdvise"
            @input="updateWorkerAdvise"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>Số người lao động được tư vấn việc làm</label>
          <b-form-input
            type="number"
            :value="workerAdviseHaveJob"
            @input="updateWorkerAdviseHaveJob"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>Số người có trình độ từ sơ cấp nghề trở lên</label>
          <b-form-input
            type="number"
            :value="workerAdviseOverPrimary"
            @input="updateWorkerAdviseOverPrimary"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>Số người sử dụng lao động được tư vấn</label>
          <b-form-input
            type="number"
            :value="workerAdviseBussiness"
            @input="updateWorkerAdviseBussiness"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    workerAdvise: {
      type: Number,
      required: true,
    },
    workerAdviseHaveJob: {
      type: Number,
      required: true,
    },
    workerAdviseOverPrimary: {
      type: Number,
      required: true,
    },
    workerAdviseBussiness: {
      type: Number,
      required: true,
    },
  },
  methods: {
    updateWorkerAdvise(val) {
      this.$emit('update:workerAdvise', Number(val))
    },
    updateWorkerAdviseHaveJob(val) {
      this.$emit('update:workerAdviseHaveJob', Number(val))
    },
    updateWorkerAdviseOverPrimary(val) {
      this.$emit('update:workerAdviseOverPrimary', Number(val))
    },
    updateWorkerAdviseBussiness(val) {
      this.$emit('update:workerAdviseBussiness', Number(val))
    },
  },
}
</script>
