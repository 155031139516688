<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người lao động đã nộp hồ sơ đề nghị hưởng bảo hiểm thất
            nghiệp</label>
          <b-form-input
            type="number"
            :value="workerUnemploymentInsurance"
            @input="updateWorkerUnemploymentInsurance"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người có quyết định hưởng trợ cấp thất nghiệp</label>
          <b-form-input
            type="number"
            :value="workerHaveInsurance"
            @input="updateWorkerHaveInsurance"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người được tư vấn việc làm</label>
          <b-form-input
            type="number"
            :value="workerUnemploymentInsuranceAdvise"
            @input="updateWorkerUnemploymentInsuranceAdvise"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người được giới thiệu việc làm</label>
          <b-form-input
            type="number"
            :value="workerUnemploymentInsuranceIntroduce"
            @input="updateWorkerUnemploymentInsuranceIntroduce"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người được hỗ trợ đào tạo nghề</label>
          <b-form-input
            type="number"
            :value="workerUnemploymentInsuranceTraining"
            @input="updateWorkerUnemploymentInsuranceTraining"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    workerHaveInsurance: {
      type: Number,
      required: true,
    },
    workerUnemploymentInsurance: {
      type: Number,
      required: true,
    },
    workerUnemploymentInsuranceAdvise: {
      type: Number,
      required: true,
    },
    workerUnemploymentInsuranceIntroduce: {
      type: Number,
      required: true,
    },
    workerUnemploymentInsuranceTraining: {
      type: Number,
      required: true,
    },
  },
  methods: {
    updateWorkerHaveInsurance(val) {
      this.$emit('update:workerHaveInsurance', Number(val))
    },
    updateWorkerUnemploymentInsurance(val) {
      this.$emit('update:workerUnemploymentInsurance', Number(val))
    },
    updateWorkerUnemploymentInsuranceAdvise(val) {
      this.$emit('update:workerUnemploymentInsuranceAdvise', Number(val))
    },
    updateWorkerUnemploymentInsuranceIntroduce(val) {
      this.$emit('update:workerUnemploymentInsuranceIntroduce', Number(val))
    },
    updateWorkerUnemploymentInsuranceTraining(val) {
      this.$emit('update:workerUnemploymentInsuranceTraining', Number(val))
    },
  },
}
</script>
