<template>
  <b-tabs content-class="mt-3">
    <b-tab
      title="Đăng ký tìm việc làm"
      active
    >
      <apply
        :workerRegister.sync="data.workerRegister"
        :workerRegisterOverPrimary.sync="data.workerRegisterOverPrimary"
        :workerRegisterIntroduce.sync="data.workerRegisterIntroduce"
        :workerRegisterIntroduceOverPrimary.sync="data.workerRegisterIntroduceOverPrimary"
        :workerRegisterRecruit.sync="data.workerRegisterRecruit"
        :workerRegisterRecruitOverPrimary.sync="data.workerRegisterRecruitOverPrimary"
      />
    </b-tab>
    <b-tab
      title="Người sử dụng lao động đề nghị cung ứng/tuyển lao động"
    >
      <recruite
        :workerRequest.sync="data.workerRequest"
        :workerRequestOverPrimary.sync="data.workerRequestOverPrimary"
        :workerSupply.sync="data.workerSupply"
        :workerSupplyOverPrimary.sync="data.workerSupplyOverPrimary"
        :workerRecruit.sync="data.workerRecruit"
        :workerRecruitOverPrimary.sync="data.workerRecruitOverPrimary"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Apply from './Apply.vue'
import Recruite from './Recruite.vue'

export default {
  components: {
    BTabs,
    BTab,
    Apply,
    Recruite,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {
  //     data: {},
  //   }
  // },
  // watch: {
  //   data: {
  //     deep: true,
  //     immediate: true,
  //     handler(val) {
  //       const data = JSON.parse(JSON.stringify(val))
  //       this.detailPlan = {
  //         workerRegister: data.workerRegister,
  //         workerRegisterOverPrimary: data.workerRegisterOverPrimary,
  //         workerRegisterIntroduce: data.workerRegisterIntroduce,
  //         workerRegisterIntroduceOverPrimary: data.workerRegisterIntroduceOverPrimary,
  //         workerRegisterRecruit: data.workerRegisterRecruit,
  //         workerRegisterRecruitOverPrimary: data.workerRegisterRecruitOverPrimary,
  //         workerRequest: data.workerRequest,
  //         workerRequestOverPrimary: data.workerRequestOverPrimary,
  //         workerSupply: data.workerSupply,
  //         workerSupplyOverPrimary: data.workerSupplyOverPrimary,
  //         workerRecruit: data.workerRecruit,
  //         workerRecruitOverPrimary: data.workerRecruitOverPrimary,
  //       }
  //     },
  //   },
  // },
}
</script>
