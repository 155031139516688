<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>Năm</label>
          <b-form-input
            type="number"
            :value="year"
            @input="updateYear"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>Kỳ báo cáo</label>
          <validation-provider
            #default="{ errors }"
            name="Năm"
            rules="required"
            :custom-messages="customCode"
          >
            <v-select
              :value="planType"
              :reduce="(value) => value.key"
              label="value"
              :options="comboboxPlanType"
              placeholder="Kỳ báo cáo"
              @input="updatePlanType"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    year: {
      type: Number,
      required: true,
    },
    planType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      customCode: {
        required: 'Kỳ báo cáo là bắt buộc',
      },
      comboboxPlanType: [
        { key: 'SixMonth', value: '6 tháng' },
        { key: 'Year', value: 'Cả năm' },
      ],
    }
  },
  methods: {
    updateYear(val) {
      this.$emit('update:year', Number(val))
    },
    updatePlanType(val) {
      if (!val) {
        return
      }
      this.$emit('update:planType', val)
    },
  },
}
</script>
