import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  async getListPlan(payload) {
    try {
      const { data } = await axiosApiInstance.get('/PlanJob/planJobs', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async getDetailPlan(payload) {
    try {
      const { data } = await axiosApiInstance.get(`/PlanJob/planJobs/${payload}`)
      return data
    } catch (err) {
      return err
    }
  },
  async addPlan(payload) {
    try {
      const { data } = await axiosApiInstance.post('/PlanJob/planJobs', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async editPlan(payload) {
    try {
      const { data } = await axiosApiInstance.put('/PlanJob/planJobs', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async deletePlan(payload) {
    try {
      const { data } = await axiosApiInstance.post('/PlanJob/delete-plan-jobs', payload)
      return data
    } catch (err) {
      return err
    }
  },
}
