<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số lao động do người sử dụng lao động đề nghị cung ứng/tuyển lao
            động</label>
          <b-form-input
            type="number"
            :value="workerRequest"
            @input="updateWorkerRequest"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người đề nghị cung ứng/tuyển có trình độ từ sơ cấp nghề trở
            lên</label>
          <b-form-input
            type="number"
            :value="workerRequestOverPrimary"
            @input="updateWorkerRequestOverPrimary"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số lao động đã cung ứng/tuyển theo đề nghị của người sử dụng lao
            động</label>
          <b-form-input
            type="number"
            :value="workerSupply"
            @input="updateWorkerSupply"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số lao động đã cung ứng/tuyển có trình độ từ sơ cấp nghề trở
            lên</label>
          <b-form-input
            type="number"
            :value="workerSupplyOverPrimary"
            @input="updateWorkerSupplyOverPrimary"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số lao động cung ứng/tuyển được người sử dụng lao động tuyển
            dụng</label>
          <b-form-input
            type="number"
            :value="workerRecruit"
            @input="updateWorkerRecruit"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số lao động đã tuyển dụng có trình độ sơ cấp trở lên</label>
          <b-form-input
            type="number"
            :value="workerRecruitOverPrimary"
            @input="updateWorkerRecruitOverPrimary"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    workerRequest: {
      type: Number,
      required: true,
    },
    workerRequestOverPrimary: {
      type: Number,
      required: true,
    },
    workerSupply: {
      type: Number,
      required: true,
    },
    workerSupplyOverPrimary: {
      type: Number,
      required: true,
    },
    workerRecruit: {
      type: Number,
      required: true,
    },
    workerRecruitOverPrimary: {
      type: Number,
      required: true,
    },
  },
  methods: {
    updateWorkerRequest(val) {
      this.$emit('update:workerRequest', Number(val))
    },
    updateWorkerRequestOverPrimary(val) {
      this.$emit('update:workerRequestOverPrimary', Number(val))
    },
    updateWorkerSupply(val) {
      this.$emit('update:workerSupply', Number(val))
    },
    updateWorkerSupplyOverPrimary(val) {
      this.$emit('update:workerSupplyOverPrimary', Number(val))
    },
    updateWorkerRecruit(val) {
      this.$emit('update:workerRecruit', Number(val))
    },
    updateWorkerRecruitOverPrimary(val) {
      this.$emit('update:workerRecruitOverPrimary', Number(val))
    },
  },
}
</script>
