<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người đăng ký tìm việc làm</label>
          <b-form-input
            type="number"
            :value="workerRegister"
            @input="updateWorkerRegister"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người đề nghị cung ứng/tuyển có trình độ từ sơ cấp nghề trở
            lên</label>
          <b-form-input
            type="number"
            :value="workerRegisterOverPrimary"
            @input="updateWorkerRegisterOverPrimary"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người đăng ký tìm việc làm được giới thiệu việc làm</label>
          <b-form-input
            type="number"
            :value="workerRegisterIntroduce"
            @input="updateWorkerRegisterIntroduce"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người giới thiệu việc làm có trình độ từ sơ cấp nghề trở
            lên</label>
          <b-form-input
            type="number"
            :value="workerRegisterIntroduceOverPrimary"
            @input="updateWorkerRegisterIntroduceOverPrimary"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người được tuyển dụng do Trung tâm giới thiệu</label>
          <b-form-input
            type="number"
            :value="workerRegisterRecruit"
            @input="updateWorkerRegisterRecruit"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người được tuyển dụng có trình độ từ sơ cấp nghề trở lên</label>
          <b-form-input
            type="number"
            :value="workerRegisterRecruitOverPrimary"
            @input="updateWorkerRegisterRecruitOverPrimary"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    workerRegister: {
      type: Number,
      required: true,
    },
    workerRegisterOverPrimary: {
      type: Number,
      required: true,
    },
    workerRegisterIntroduce: {
      type: Number,
      required: true,
    },
    workerRegisterIntroduceOverPrimary: {
      type: Number,
      required: true,
    },
    workerRegisterRecruit: {
      type: Number,
      required: true,
    },
    workerRegisterRecruitOverPrimary: {
      type: Number,
      required: true,
    },
  },
  methods: {
    updateWorkerRegister(val) {
      this.$emit('update:workerRegister', Number(val))
    },
    updateWorkerRegisterOverPrimary(val) {
      this.$emit('update:workerRegisterOverPrimary', Number(val))
    },
    updateWorkerRegisterIntroduce(val) {
      this.$emit('update:workerRegisterIntroduce', Number(val))
    },
    updateWorkerRegisterIntroduceOverPrimary(val) {
      this.$emit('update:workerRegisterIntroduceOverPrimary', Number(val))
    },
    updateWorkerRegisterRecruit(val) {
      this.$emit('update:workerRegisterRecruit', Number(val))
    },
    updateWorkerRegisterRecruitOverPrimary(val) {
      this.$emit('update:workerRegisterRecruitOverPrimary', Number(val))
    },
  },
}
</script>
