<template>
  <div id="addBusiness">
    <my-tabs
      :listTabs="listTabs"
      defaultActive="info"
      typeCss="active-full"
      @setActive="setActive"
    />
    <div class="page-container">
      <info
        v-if="isRendered('info')"
        class="tab-pane"
        :year.sync="detailPlan.year"
        :planType.sync="detailPlan.planType"
        :class="{ active: activeTab === 'info' }"
      />
      <session
        v-if="isRendered('session')"
        class="tab-pane"
        :class="{ active: activeTab === 'session' }"
        :jobSession.sync="detailPlan.jobSession"
        :bussinessJobSession.sync="detailPlan.bussinessJobSession"
        :workerAdviseJobSession.sync="detailPlan.workerAdviseJobSession"
        :workerHaveJobJobSession.sync="detailPlan.workerHaveJobJobSession"
        :workerInterviewJobSession.sync="detailPlan.workerInterviewJobSession"
      />
      <advise
        v-if="isRendered('advise')"
        class="tab-pane"
        :class="{ active: activeTab === 'advise' }"
        :workerAdvise.sync="detailPlan.workerAdvise"
        :workerAdviseHaveJob.sync="detailPlan.workerAdviseHaveJob"
        :workerAdviseOverPrimary.sync="detailPlan.workerAdviseOverPrimary"
        :workerAdviseBussiness.sync="detailPlan.workerAdviseBussiness"
      />
      <recommend
        v-if="isRendered('recommend')"
        ref="recommend"
        :data="detailPlan"
        class="tab-pane"
        :class="{ active: activeTab === 'recommend' }"
        @update="updateRecommend"
      />
      <training
        v-if="isRendered('training')"
        class="tab-pane"
        :class="{ active: activeTab === 'training' }"
        :workerTraining.sync="detailPlan.workerTraining"
        :workerTrainingSkill.sync="detailPlan.workerTrainingSkill"
        :workerTrainingJob.sync="detailPlan.workerTrainingJob"
        :workerTrainingRecruit.sync="detailPlan.workerTrainingRecruit"
      />
      <insurance
        v-if="isRendered('insurance')"
        class="tab-pane"
        :class="{ active: activeTab === 'insurance' }"
        :workerHaveInsurance.sync="detailPlan.workerHaveInsurance"
        :workerUnemploymentInsurance.sync="detailPlan.workerUnemploymentInsurance"
        :workerUnemploymentInsuranceAdvise.sync="detailPlan.workerUnemploymentInsuranceAdvise"
        :workerUnemploymentInsuranceIntroduce.sync="detailPlan.workerUnemploymentInsuranceIntroduce"
        :workerUnemploymentInsuranceTraining.sync="detailPlan.workerUnemploymentInsuranceTraining"
      />
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          class="mr-2"
          @click="save"
        >
          Lưu lại
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Hủy bỏ
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import MyTabs from '@/components/tabs/MyTabs.vue'
import Info from './components/Info.vue'
import Advise from './components/Advise.vue'
import Insurance from './components/Insurance.vue'
import Recommend from './components/recommend/Recommend.vue'
import Session from './components/Session.vue'
import Training from './components/Training.vue'
import store from '../store'

export default {
  components: {
    MyTabs,
    Info,
    Advise,
    Insurance,
    Recommend,
    Session,
    Training,
    BButton,
  },
  data() {
    return {
      detailPlan: {
        bussinessJobSession: 0,
        jobSession: 0,
        planType: '',
        workerAdvise: 0,
        workerAdviseBussiness: 0,
        workerAdviseHaveJob: 0,
        workerAdviseJobSession: 0,
        workerAdviseOverPrimary: 0,
        workerHaveInsurance: 0,
        workerHaveJobJobSession: 0,
        workerInterviewJobSession: 0,
        workerRecruit: 0,
        workerRecruitOverPrimary: 0,
        workerRegister: 0,
        workerRegisterIntroduce: 0,
        workerRegisterIntroduceOverPrimary: 0,
        workerRegisterOverPrimary: 0,
        workerRegisterRecruit: 0,
        workerRegisterRecruitOverPrimary: 0,
        workerRequest: 0,
        workerRequestOverPrimary: 0,
        workerSupply: 0,
        workerSupplyOverPrimary: 0,
        workerTraining: 0,
        workerTrainingJob: 0,
        workerTrainingRecruit: 0,
        workerTrainingSkill: 0,
        workerUnemploymentInsurance: 0,
        workerUnemploymentInsuranceAdvise: 0,
        workerUnemploymentInsuranceIntroduce: 0,
        workerUnemploymentInsuranceTraining: 0,
        year: 0,
      },
      activeTab: null,
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'session',
          title: 'Tổ chức phiên giao dịch việc làm',
          icon: 'BriefcaseIcon',
          isRendered: false,
        },
        {
          key: 'advise',
          title: 'Hoạt động tư vấn',
          icon: 'MessageCirleIcon',
          isRendered: false,
        },
        {
          key: 'recommend',
          title: 'Hoạt động giới thiệu việc làm, cung ứng và tuyển lao động',
          icon: 'UsersIcon',
          isRendered: false,
        },
        {
          key: 'training',
          title: 'Tổ chức đào tạo, tập huấn',
          icon: 'CheckSquareIcon',
          isRendered: false,
        },
        {
          key: 'insurance',
          title: 'Chính sách bảo hiểm thất nghiệp',
          icon: 'ClipboardIcon',
          isRendered: false,
        },
      ],
      detailRecommend: {},
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  created() {
    if (this.activeTab === null) {
      this.activeTab = 'info'
      this.listTabs[0].isRendered = true
    }
    if (this.$route.params.id) {
      this.getDetailPlan()
    }
  },
  methods: {
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },
    async getDetailPlan() {
      const res = await store.getDetailPlan(this.$route.params.id)
      this.detailPlan = res
    },
    async save() {
      this.detailPlan = { ...this.detailPlan, ...this.detailRecommend }
      let variant = ''
      let message = ''
      if (this.$route.params.id) {
        const res = await store.editPlan(this.detailPlan)
        if (res.success) {
          variant = 'success'
          message = 'Chỉnh sửa kế hoạch dịch vụ việc làm thành công'
        } else {
          variant = 'danger'
          message = 'Chỉnh sửa kế hoạch dịch vụ việc làm thất bại'
        }
      } else {
        const res = await store.addPlan(this.detailPlan)

        if (res.success) {
          variant = 'success'
          message = 'Thêm kế hoạch dịch vụ việc làm thành công'
        } else {
          variant = 'danger'
          message = 'Thêm kế hoạch dịch vụ việc làm thất bại'
        }
      }
      this.$root.$root.$bvToast.toast(message, {
        title: 'Thông báo',
        variant,
        toaster: this.$toastPosition,
        solid: true,
      })
      this.$router.push({ name: 'plan-employment' })
    },
    cancel() {
      this.$router.push({ name: 'plan-employment' })
    },
    updateRecommend(val) {
      this.detailRecommend = val
    },
  },
}
</script>
