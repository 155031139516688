<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số phiên đã thực hiện</label>
          <b-form-input
            type="number"
            :value="jobSession"
            @input="updateJobSession"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số doanh nghiệp tham gia phiên giao dịch việc làm</label>
          <b-form-input
            type="number"
            :value="bussinessJobSession"
            @input="updateBussinessJobSession"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người lao động được tư vấn tại phiên giao dịch việc làm</label>
          <b-form-input
            type="number"
            :value="workerAdviseJobSession"
            @input="updateWorkerAdviseJobSession"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người lao động được phỏng vấn tại phiên giao dịch việc
            làm</label>
          <b-form-input
            type="number"
            :value="workerInterviewJobSession"
            @input="updateWorkerInterviewJobSession"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label>Số người lao động nhận được việc làm sau khi phỏng vấn tại phiên
            giao dịch việc làm</label>
          <b-form-input
            type="number"
            :value="workerHaveJobJobSession"
            @input="updateWorkerHaveJobJobSession"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    jobSession: {
      type: Number,
      required: true,
    },
    bussinessJobSession: {
      type: Number,
      required: true,
    },
    workerAdviseJobSession: {
      type: Number,
      required: true,
    },
    workerHaveJobJobSession: {
      type: Number,
      required: true,
    },
    workerInterviewJobSession: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      code: '',
      customCode: {
        required: 'Mã phiên giao dịch là bắt buộc',
      },
    }
  },
  methods: {
    updateJobSession(val) {
      this.$emit('update:jobSession', Number(val))
    },
    updateBussinessJobSession(val) {
      this.$emit('update:bussinessJobSession', Number(val))
    },
    updateWorkerAdviseJobSession(val) {
      this.$emit('update:workerAdviseJobSession', Number(val))
    },
    updateWorkerHaveJobJobSession(val) {
      this.$emit('update:workerHaveJobJobSession', Number(val))
    },
    updateWorkerInterviewJobSession(val) {
      this.$emit('update:workerInterviewJobSession', Number(val))
    },
  },
}
</script>
